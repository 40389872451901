






import Vue from "vue";
import MemberBox from "../../components/Page/MemberBox.vue";
export default Vue.extend({
	name: "Members",
	components: {
		MemberBox
	},
	data() {
		return {
			members: []
		};
	},
	created() {
		this.$store.commit("pushPageLoading", {
			name: "GET_CLUB_MEMBERS",
			message: "동아리 멤버 불러오는 중"
		});
		this.$store
			.dispatch("GET_CLUB_MEMBERS")
			.then(members => {
				this.$store.commit("clearPageLoading", "GET_CLUB_MEMBERS");
				this.members = members;
			})
			.catch(err => {});
	}
});
