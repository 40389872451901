












import Vue from "vue";
export default Vue.extend({
	props: {
		data: Object
	},
	computed: {
		getMainPath(): string {
			return this.$store.state.mainPath;
		}
	}
});
